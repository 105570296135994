<template>
  <div>
    <div class="select is-multiple is-fullwidth">
      <div class="control is-expanded">
        <multi-select
          :selected-value="[]"
          :options="bankAccountsOptions"
          placeholder="Recipient Account"
          send-parameter="name"
          @change="handleChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from 'vue';
import MultiSelect from '../common/MultiSelect.vue';

export default {
    components: { MultiSelect },
    emits: ['update:status'],
    async setup() {
        const {
            fetchBankAccounts,
        } = inject('bank_accounts');
        const bankAccounts = await fetchBankAccounts();
        const bankAccountsOptions = bankAccounts.map((bankAccount) => {
            const options = {
                name: `${bankAccount.accountNumber}/${bankAccount.bank.name}/${bankAccount.companyName}`,
            };
            return options;
        });
        return {
            bankAccountsOptions,
        };
    },
    methods: {
        handleChange(value) {
            this.$emit('update:status', value);
        },
    },
};
</script>
