import { httpGet, httpPost } from '../http';

export default () => {
    const snakeToCamel = (res) => res.map(({
        bank_account_id: bankAccountId,
        company_name: companyName,
        account_number: accountNumber,
        branch_code: branchCode,
        branch_name: branchName,
        bank,
    }) => ({
        bankAccountId,
        companyName,
        accountNumber,
        branchCode,
        branchName,
        bank,
    }));

    const camelToSnake = (data) => ({
        company_name: data.companyName,
        account_number: data.accountNumber,
        branch_code: data.branchCode,
        branch_name: data.branchName,
        bank_id: data.bankId,
    });

    const fetchBankAccounts = async () => {
        const response = await httpGet(
            'bank-accounts/',
        );

        return snakeToCamel(response);
    };

    const postBankAccount = async (data) => {
        const response = await httpPost(
            'bank-accounts/',
            {},
            camelToSnake(data),
        );

        return snakeToCamel([response])[0];
    };

    const fetchConnectedBanks = async (merchantId, groupType) => {
        const response = await httpGet(
            `bank-accounts/connect/${merchantId}`,
            {
                group_type: groupType,
            },
        );

        return response;
    };

    const camelToSnakeConnect = (data) => ({
        bank_account_id: data.bankAccountId,
        merchant_id: data.merchantId,
        type: data.type,
    });

    const connect = async (data) => {
        await httpPost(
            'bank-accounts/connect/',
            {},
            camelToSnakeConnect(data),
        );
    };

    const disconnect = async (data) => {
        await httpPost(
            'bank-accounts/disconnect/',
            {},
            camelToSnakeConnect(data),
        );
    };

    return {
        connect,
        disconnect,
        fetchBankAccounts,
        fetchConnectedBanks,
        postBankAccount,
    };
};
