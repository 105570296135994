<template>
  <thead>
    <tr class="is-centered">
      <OrderingToggle
        field="transactionId"
      >
        Transaction ID
      </OrderingToggle>
      <OrderingToggle
        field="email"
      >
        Email
      </OrderingToggle>
      <OrderingToggle
        field="date"
      >
        Creation Date
      </OrderingToggle>
      <OrderingToggle
        field="settled_amount"
      >
        Settled Amount
      </OrderingToggle>
      <OrderingToggle
        field="firstName"
      >
        First Name
      </OrderingToggle>
      <OrderingToggle
        field="surname"
      >
        Surname
      </OrderingToggle>
      <OrderingToggle
        field="senderName"
      >
        Sender Name
      </OrderingToggle>
      <OrderingToggle
        field="bankName"
      >
        Bank Name
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayBranchBankCompanyFields"
        field="bankCode"
      >
        Bank Code
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayBranchBankCompanyFields"
        field="branchCode"
      >
        Branch Code
      </OrderingToggle>
      <OrderingToggle
        field="accountNumber"
      >
        Account Number
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayBranchBankCompanyFields"
        field="companyName"
      >
        Company Name
      </OrderingToggle>
      <OrderingToggle
        field="nonce"
      >
        Nonce
      </OrderingToggle>
      <OrderingToggle
        field="merchantId"
      >
        Merchant
      </OrderingToggle>
      <OrderingToggle
        field="transactionRequestDate"
      >
        Receipt Date
      </OrderingToggle>
      <OrderingToggle
        field="status"
      >
        Status
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayCreation"
        field="creation"
      >
        Creation
      </OrderingToggle>
      <OrderingToggle
        field="transactionFee"
      >
        Transaction Fee
      </OrderingToggle>
      <OrderingToggle
        v-if="accountingUser"
        field="processingFee"
      >
        Processing Fee
      </OrderingToggle>
      <OrderingToggle
        field="authorizedAmount"
      >
        Authorized Amount
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplaySourceBankName"
        field="sourceBankName"
      >
        Source Bank Name
      </OrderingToggle>
      <th>Edit</th>
      <th v-if="!accountingUser">
        Received
      </th>
      <OrderingToggle
        field="receivedBy"
      >
        Operator
      </OrderingToggle>
    </tr>
  </thead>
</template>

<script>
import OrderingToggle from './OrderingToggle.vue';
import initializeFlagsmith from '../flagsmith';

export default {
    name: 'OrderableTableHeader',

    components: {
        OrderingToggle,
    },

    props: {
        accountingUser: {
            type: Boolean,
            required: true,
        },
    },

    async setup() {
        const flagsmith = await initializeFlagsmith();

        const shouldDisplaySourceBankName = flagsmith.hasFeature('btb_source_bank');
        const shouldDisplayCreation = flagsmith.hasFeature('creation_status_btb');
        const shouldDisplayBranchBankCompanyFields = flagsmith.hasFeature('operator_branch_bank_company_fields');

        return {
            shouldDisplaySourceBankName,
            shouldDisplayCreation,
            shouldDisplayBranchBankCompanyFields,
        };
    },
};
</script>
