<template>
  <tr class="is-centered">
    <td>{{ transaction.transactionId }}</td>
    <td>{{ transaction.email }}</td>
    <td>{{ dateToCET(transaction.timestamp) }}</td>
    <td>{{ formatAmount(transaction.settledAmount, 'JPY', transaction.creation) }}</td>
    <td
      ref="targetFirstName"
      @mouseover="translateOnHover(transaction.firstName, targetFirstName)"
    >
      {{ transaction.firstName }}
    </td>
    <td
      ref="targetSurname"
      @mouseover="translateOnHover(transaction.surname, targetSurname)"
    >
      {{ transaction.surname }}
    </td>
    <td>{{ transaction.senderName }}</td>
    <td>{{ transaction.bankName }}</td>
    <td>{{ transaction.accountNumber }}</td>
    <td>{{ transaction.nonce }}</td>
    <td>{{ transaction.merchantId }}</td>
    <td
      v-if="[
        'CREATED', 'PRECREATED', 'INITIALIZED', 'BLOCKED'
      ].includes(transaction.status)"
    />
    <td v-else>
      {{ dateToCET(transaction.transactionRequestDate) }}
    </td>
    <td>
      <StatusIcon
        :status="transaction.status"
      />
    </td>
    <td v-if="shouldDisplayCreation">
      <span
        class="p-1"
        :class="classNameForCreation(transaction.creation)"
      >
        {{ transaction.creation }}
      </span>
    </td>
    <td>{{ formatTransactionFee(transaction.transactionFee, transaction.currency, 'SYSTEM') }}</td>
    <td v-if="accountingUser">
      {{ formatAmount(transaction.processingFee, transaction.currency, 'SYSTEM') }}
    </td>
    <td>
      {{ formatAmount(transaction.authorizedAmount, transaction.currency, transaction.creation) }}
    </td>
    <td v-if="shouldDisplaySourceBankName">
      {{ transaction.sourceBankName }}
    </td>
    <td>
      <button
        v-if="transaction.creation !== 'MANUAL' && transaction.status === 'CREATED'"
        class="is-clickable button is-small is-primary modal-button"
        @click="edit(transaction)"
      >
        <i class="fas fa-lg fa-pencil-alt" />
      </button>
    </td>
    <td>
      <ReceiveTransaction
        v-if="transaction.status === 'CREATED' && !accountingUser"
        :transaction-id="transaction.transactionId"
      />
    </td>
    <td>{{ transaction.receivedBy }}</td>
  </tr>
</template>

<script>
import * as AWS from 'aws-sdk';
import { ref } from 'vue';
import { useTippy } from 'vue-tippy';
import ReceiveTransaction from './ReceiveTransaction.vue';
import StatusIcon from './StatusIcon.vue';
import initializeFlagsmith from '../flagsmith';
import 'tippy.js/dist/tippy.css';

export default {
    components: {
        StatusIcon,
        ReceiveTransaction,
    },

    props: {
        transaction: {
            type: Object,
            required: true,
        },
        edit: {
            type: Function,
            required: true,
        },
        accountingUser: {
            type: Boolean,
            required: true,
        },
    },

    async setup() {
        const {
            VUE_APP_AWS_ACCESS_KEY_ID,
            VUE_APP_AWS_SECRET_ACCESS_KEY,
            VUE_APP_AWS_DEFAULT_REGION,
        } = process.env;

        const targetFirstName = ref(null);
        const targetSurname = ref(null);

        const translate = new AWS.Translate({
            apiVersion: '',
            accessKeyId: VUE_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: VUE_APP_AWS_SECRET_ACCESS_KEY,
            region: VUE_APP_AWS_DEFAULT_REGION,
        });

        const translateName = async (name) => {
            try {
                const params = {
                    SourceLanguageCode: 'ja',
                    TargetLanguageCode: 'en',
                    Text: name,
                };
                const translation = await translate.translateText(params).promise();
                return translation.TranslatedText;
            } catch (error) {
                console.error(error, error.stack);
            }
            return null;
        };

        const translateOnHover = (name, target) => {
            translateName(name).then((result) => {
                // eslint-disable-next-line no-underscore-dangle
                if (!target._tippy) {
                    useTippy(target, {
                        content: result,
                    });
                    // eslint-disable-next-line no-underscore-dangle
                    target._tippy.show();
                }
            });
        };

        const formatAmount = (amount, curr, creation) => {
            if (creation === 'REFUND' || creation === 'TRANSFER BACK') {
                return new Intl.NumberFormat('en-US', { style: 'currency', currency: curr }).format((amount / 100) * -1);
            }

            return new Intl.NumberFormat('en-US', { style: 'currency', currency: curr }).format(amount / 100);
        };

        const formatTransactionFee = (amount, curr, creation) => {
            if (amount === '') {
                return '';
            }

            return formatAmount(amount, curr, creation);
        };

        const dateToCET = (date) => `${date.toLocaleString('en-GB', {
            timeZone: 'CET',
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        })} CET`;

        const classNameForCreation = (value) => {
            const values = {
                MODIFIED: 'has-background-warning',
                MANUAL: 'has-background-danger has-text-white',
                REFUND: 'has-background-info has-text-white',
                'TRANSFER BACK': 'has-text-info',
                AUTO: 'has-background-black has-text-white',
                SYSTEM: 'has-text-danger-dark',
            };

            return values[value] || 'has-text-black-bis';
        };

        const flagsmith = await initializeFlagsmith();

        const shouldDisplaySourceBankName = flagsmith.hasFeature('btb_source_bank');
        const shouldDisplayCreation = flagsmith.hasFeature('creation_status_btb');
        const shouldDisplayBranchBankCompanyFields = flagsmith.hasFeature('operator_branch_bank_company_fields');

        return {
            formatAmount,
            formatTransactionFee,
            dateToCET,
            shouldDisplaySourceBankName,
            classNameForCreation,
            shouldDisplayCreation,
            shouldDisplayBranchBankCompanyFields,
            targetFirstName,
            targetSurname,
            translate,
            translateName,
            translateOnHover,
        };
    },
};
</script>

<style>
.is-not-wrapped td {
  white-space: nowrap;
}
</style>
